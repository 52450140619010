* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
  background: #12c2e9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

body, html, .App, #root, .outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 50%;
  min-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.form-validation-error {
  color: red;
  font-size: 12px;
}

.hidden {
  display: none;
}



/* List View */

.SimpleList * {margin: 0; padding: 0;}

.SimpleList {
  margin: 5px;
}

.SimpleList ul {
  list-style-type: none;
  width: 90%;
}

.SimpleList ul li h3 {
  font: bold 20px/1.5 Helvetica, Verdana, sans-serif;
  margin-top: 10px;
  margin-left: 60px;
  text-align: left;
}

.SimpleList li img {
  float: left;
  margin: 0 -100px 0 0;
  width: 50px;
}

.SimpleList ul li p {
  font: 200 12px/1.5 Georgia, Times New Roman, serif;
}

.SimpleList ul li {
  padding: 10px;
  overflow: auto;
  margin-left: 50px;
}

.SimpleList li:hover {
  background: #eee;
  cursor: pointer;
}

.SimpleGroup {
  padding-top: 15px;
}

.listViewContain {
  max-height: 520px;
  overflow: auto;
}

.listViewContainLarge {
  max-height: 555px;
  overflow: auto;
}
